<template>
  <div>
    <Card body-classes="">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between">
          <span>Образовательные дни</span>

          <div class="mb-0 w-55">
            <Button type="success" @click="newDay()">Добавить новый</Button>
          </div>

          <Input
            v-model="dataTableSearch"
            size="sm"
            placeholder="Поиск"
            class="mb-0 w-25"
          />
        </div>
      </template>
      <div>
        <DataTable
          class="table-bordered"
          show-filter-buttons
          :data="dataTable"
          :headers="dataTableHeaders"
          :search-keyword="dataTableSearch"
          :per-page="dataTablePerPage"
          :current-page="dataTablePage"
        >
          <template v-slot:default="{ row, rowKey }">
            <Cell
              v-for="(cellData, key) in row"
              :class="[{'bg-gray-200': false}]"
              :key="key"
            >
              <span v-if="key.toString().toLowerCase() === '_id'">{{rowKey + 1}}</span>
              <span v-else-if="key.toString().toLowerCase() === 'edit'">
                  <button class="btn btn-info" @click="editDay(row._id)">Редактировать</button>
              </span>
              <Badge v-else-if="key.toString().toLowerCase() === 'status'" pill :type="getPillByStatus(cellData)">
                {{cellData}}
              </Badge>
              <span v-else>{{ cellData }}</span>
            </Cell>
          </template>
        </DataTable>
      </div>
      <div
        class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-end"
      >
        <div class="me-sm-4 mb-sm-0 mb-3">
          <p class="mb-2 small">Дней на странице</p>
          <Select
            :data="[5, 10, 15, 25, 100]"
            :selected="dataTablePerPage"
            @change="(dataTablePerPage = $event), (dataTablePage = 1)"
          ></Select>
        </div>
        <div>
          <Pagination
            v-model="dataTablePage"
            :pages="Math.ceil(dataTable.length / dataTablePerPage)"
          >
            <template v-slot:next> Дальше </template>
            <template v-slot:previous> Предыдущая </template>
          </Pagination>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import DayService from "../../services/DayService";

export default {
  name: "DayTables",
  data() {
    return {
      dataTableHeaders: [
        "Номер",
        "Название",
        "Курс",
        "Количество заданий",
        "Количество очков",
        "Статус",
        "Действия"
      ],
      dataTable: [],
      dataTableSearch: "",
      dataTablePage: 1,
      dataTablePerPage: 10,
    };
  },
  async mounted() {
    const days = await DayService.getAll();
    days.forEach((day, index) => {
      this.$set(this.dataTable, index, {
        '_id': day._id,
        'name': day.name,
        'course_name': day.course_name,
        'num_tasks': day.num_tasks,
        'num_task_ponts': day.num_task_ponts,
        'status': DayService.getStatusName(day.status),
        'edit': null
      });
    });
  },
  methods: {
    editDay(id) {
      this.$router.push('/days/' + id);
    },
    newDay()
    {
      this.$router.push('/days/new');
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case "Активный":
            return 'success';
        case "Архив":
            return 'warning';
      }
    }
  }
};
</script>

<style scoped>
</style>